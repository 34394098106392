<script lang="ts">
    import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
    import { Icon, EllipsisHorizontal, ArrowLeft } from 'svelte-hero-icons';

    export let activeUser;
    export let mobileShowChat;
    import { Button } from '$lib/components/ui/button';
</script>

<div class="conversation-header">
    <div class="details-holder border-b px-3 pb-3 pt-4">
        <div class="flex items-center px-1">
            <div class="flex w-[80%] items-center pl-0">
                <Button
                    variant="ghost"
                    class="px-2 py-1 text-bmn-500 hover:bg-transparent hover:text-bmn-300 focus:bg-transparent md:hidden"
                    on:click="{() => {
                        mobileShowChat = false;
                    }}"
                >
                    <Icon src="{ArrowLeft}" size="{'24px'}" solid />
                </Button>
                <div class="h-[50px] w-[50px] overflow-hidden rounded-[100%]">
                    <img class="conversation-header-avatar" src="{activeUser[2]}" alt="avatar" />
                </div>
                <div class="conversation-header-user text-truncate ml-3">
                    {activeUser[3]}
                </div>
            </div>
            <div class="flex w-[20%] justify-end pr-0">
                <DropdownMenu.Root>
                    <DropdownMenu.Trigger
                        class="rounded-md border-[1px] border-bmn-500 bg-transparent px-2 py-0 text-bmn-500 hover:border-bmn-300 hover:text-bmn-300"
                    >
                        <Icon src="{EllipsisHorizontal}" size="{'22px'}" solid />
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content>
                        <DropdownMenu.Group>
                            <DropdownMenu.Item>Send a tip</DropdownMenu.Item>
                            <DropdownMenu.Item>
                                <Button
                                    variant="link"
                                    class="h-auto w-full justify-start bg-none p-0 text-black hover:no-underline dark:text-white"
                                    href="{activeUser[4]}">Go to profile</Button
                                >
                            </DropdownMenu.Item>
                            <DropdownMenu.Separator />
                            <DropdownMenu.Item>Unfollow</DropdownMenu.Item>
                            <DropdownMenu.Item>Block</DropdownMenu.Item>
                            <DropdownMenu.Item>Report</DropdownMenu.Item>
                        </DropdownMenu.Group>
                    </DropdownMenu.Content>
                </DropdownMenu.Root>
            </div>
        </div>
    </div>
</div>
